/**
 * NAVIGATION
 */

 const navigation = () => {
  const menuBtn = document.querySelector('.js-hamburger');
  const menu = document.querySelector('.header__menu');
  const menuLabel = document.querySelector('.header__hamburger-text');
  const nav = document.querySelector('.header__main');

  // Nav toggle
  if(!menu || !menuBtn || !nav) return;
  menuBtn.addEventListener('click', () => {
    menu.classList.toggle('toggle');
    menuBtn.classList.toggle('toggle');
    nav.classList.toggle("toggle");
    // document.documentElement.classList.toggle('overflow');

    if(screen.width < 768) {
      if(!menuLabel) return;
      menuLabel.classList.toggle("toggle");
      if(menuBtn.classList.contains("toggle")) {
        menuLabel.innerHTML = "CLOSE";
      }
      else {
        menuLabel.innerHTML = "MENU";
      }
    }
  });

  // Nav resize
  window.addEventListener("scroll", () => {
    if(screen.width > 767) {
      if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
        nav.classList.add("scroll");
        menu.classList.add("scroll");
        menuBtn.classList.add('scroll');
      }
      else {
        nav.classList.remove("scroll");
        menu.classList.remove("scroll");
        menuBtn.classList.remove('scroll');
      }
    }
  });
}

export default navigation
