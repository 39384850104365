/**
 * Scroll to top
 */

 const scrollTop = () => {
  const btn = document.querySelector('.js-scroll-top');

  if (!btn) return;
  btn.addEventListener('click', () => {
    document.body.scrollIntoView({
        behavior: 'smooth'
    });
  });
}

export default scrollTop;
