import navigation from './components/navigation'
import scrollTop from './components/scrollTop'

document.addEventListener(
  'DOMContentLoaded',
  () => {
    navigation()
    scrollTop()
  },
  false
)
